import { FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { signup } from '../../api/auth/signup'

const APP_NAME = 'marketepr'
const APP_DESCRIPTION = 'Marketplace for trading wastes'

export const SignUpPage = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [fullName, setFullName] = useState('')
  const [contactNumber, setContactNumber] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')

  const [showSuccess, setShowSuccess] = useState(false)

  const initForm = () => {
    setUsername('')
    setPassword('')
    setFullName('')
    setContactNumber('')
  }

  const submitForm = (e: FormEvent) => {
    e.preventDefault()

    setErrMsg('')
    setIsLoading(true)

    signup(
      {
        username,
        password,
        fullName,
        contactNumber
      },
      (response) => {
        if (response.status === 201) {
          initForm()
          setShowSuccess(true)
          setIsLoading(false)
        }
        console.log(response)
      },
      (error) => {
        console.log(error)
        setErrMsg(error.response.data.message)
        setIsLoading(false)
      }
    )
  }

  const inputLabelStyle = 'text-slate-500 text-sm block my-2'
  const inputStyle = `bg-slate-white rounded text-md w-full mb-2 p-2 border border-slate-400 ${
    !isLoading && 'hover:border-slate-600 focus:border-slate-800'
  }`

  return (
    <div className="flex flex-col justify-center h-screen items-center">
      <Link
        to="/"
        className="inline-block title-bold text-2xl text-center mb-4 text-gray-700"
      >
        {APP_NAME}
        <span className="block font-primaryRegular text-sm mt-2 pb-4 text-gray-700">
          {APP_DESCRIPTION}
        </span>
      </Link>
      <div className="bg-slate-50 shadow-md p-8 rounded-md w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
        <div className="text-center">
        {showSuccess ? (
          <div className="font-bold text-4xl text-slate-900">Hooray!</div>
        ):(
          <div className='mb-4'>
            <div className="font-bold text-4xl text-slate-900 mb-2">Get Started</div>
            <div className="font-regular text-sm text-slate-500">Sign up for free!</div>
          </div>
        )}
        </div>
        {showSuccess ? (
          <div className="text-center">
            <div className='my-4 text-xl text-green-700'>You successfully created an account</div>
            <div>
              <Link
                to="/auth/login"
                className={`block text-sm font-semibold p-3 rounded text-center bg-blue-700 hover:bg-blue-500 active:bg-blue-900 cursor-pointer shadow-md text-slate-50 mt-4`}
              >
                LOGIN NOW
              </Link>
            </div>
          </div>
        ) : (
          <form className="flex flex-col gap-2" onSubmit={(e) => submitForm(e)}>
            <div>
              <label className={inputLabelStyle}>Username</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className={inputStyle}
                disabled={isLoading}
                autoFocus
                required
              />
            </div>
            <div>
              <label className={inputLabelStyle}>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={inputStyle}
                disabled={isLoading}
                required
              />
            </div>
            <div>
              <label className={inputLabelStyle}>Full Name</label>
              <input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                className={inputStyle}
                disabled={isLoading}
                required
              />
            </div>
            <div>
              <label className={inputLabelStyle}>Contact Number</label>
              <input
                type="text"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                className={inputStyle}
                disabled={isLoading}
                required
              />
            </div>
            {errMsg && (
              <div className="text-sm text-red-600 text-center">{errMsg}</div>
            )}
            <button
              type="submit"
              disabled={isLoading}
              className={`block text-sm font-semibold p-3 rounded text-center ${
                isLoading
                  ? 'bg-blue-300 cursor-wait'
                  : 'bg-blue-700 hover:bg-blue-500 active:bg-blue-900 cursor-pointer shadow-md'
              }  text-slate-50 mt-4`}
            >
              {isLoading ? 'Please wait' : 'SIGN UP'}
            </button>
          </form>
        )}
      </div>
      {showSuccess ? (
        <div className="mt-6 text-sm">
          Sign up another account? Click{' '}
          <span
            className="text-blue-700 hover:text-blue-500 active:text-blue-900 cursor-pointer"
            onClick={() => setShowSuccess(false)}
          >
            here
          </span>
        </div>
      ) : (
        <div className="mt-6 text-sm">
          Already have an account? Login{' '}
          <Link
            to="/auth/login"
            className="text-blue-700 hover:text-blue-500 active:text-blue-900 cursor-pointer"
          >
            here
          </Link>
        </div>
      )}
    </div>
  )
}
