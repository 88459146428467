import axios  from 'axios'
// import axios, { AxiosError, AxiosResponse } from 'axios'

interface IHeaders {
  [key: string]: string
}

// export type CallBackResponse = (response: AxiosResponse) => void
// export type CallBackError = (response: AxiosError) => void
export type CallBackResponse = (response: any) => void
export type CallBackError = (response: any) => void

export const createAxios = (
  baseURL?: string,
  headers?: IHeaders,
  withCredentials = false
) => {
  const api = axios.create({
    baseURL: baseURL || process.env.REACT_APP_API_URL,
    timeout: 10000,
    headers: {
      ...headers,
      'Content-Type': 'application/json'
    },
    withCredentials
  })

  return api
}
