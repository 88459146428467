type TextFieldParams = {
  handleChange: (value: string) => void,
  value?: string,
  placeholder?: string
}

export const TextField = ({ handleChange, value = '', placeholder = ''}: TextFieldParams) => {
  return (
    <input
      type="text"
      placeholder={placeholder}
      className="border-2 w-full text-slate-900 text-sm rounded-md p-3 border-gray-300 hover:border-blue-400 focus:border-blue-500"
      value={value}
      onChange={(event) => handleChange(event?.currentTarget.value)}
    />
  )
}
