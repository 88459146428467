import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { LoginPage } from './pages/auth/login'
import { SignUpPage } from './pages/auth/signup'
import { AuthPage } from './pages/auth'
import { MainPage } from './pages/main'
import { DashboardPage } from './pages/main/dashboard'
import { GalleryPage } from './pages/main/gallery'

import { NotFoundPage } from './pages/error/404'
import { AdminPage } from './pages/main/admin'
import { UsersPage } from './pages/main/admin/users'
import { ProductsPage } from './pages/main/admin/products'
import { AdminDashboardPage } from './pages/main/admin/dashboard'
import { Provider } from 'react-redux'
import { persistor, store } from './store/store'
import { PersistGate } from 'redux-persist/integration/react'
import { Protected } from './components/Protected'

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>

            {/* Auth Routes */}
            <Route path='/auth' element={ <AuthPage /> }>
              <Route path='/auth/login' element={ <LoginPage /> } />
              <Route path='/auth/signup' element={ <SignUpPage /> } />
            </Route>

            {/* Main Pages */}
            <Route path='/' element={ <MainPage />}>
              <Route path='/dashboard' element={ <Protected expectedRole="seller"> <DashboardPage /> </Protected> } />
              <Route path='/admin' element={ <Protected expectedRole="admin"> <AdminPage /> </Protected>}>
                <Route path='/admin/products' element={ <ProductsPage /> } />
                <Route path='/admin/users' element={ <UsersPage /> } />
                <Route path='/admin/dashboard' element={ <AdminDashboardPage /> } />
              </Route>
              <Route index path='/' element={ <GalleryPage /> } />
            </Route>

            {/* Error Pages */}
            <Route path='/404' element={ <NotFoundPage /> } /> 
            <Route path="*" element={ <Navigate to="/404" /> } />
            
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

export default App
