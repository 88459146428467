import { useCallback, useState } from 'react'
import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'

type ComboBoxParams = {
  options: string[]
  handleChange: (value: string) => void
  value?: string
  placeholder?: string
  label?: string
  required?: boolean
  disabled?: boolean
}

export const ComboBox = ({
  options,
  handleChange,
  value = '',
  placeholder = '',
  label = '',
  required = false,
  disabled = false
}: ComboBoxParams) => {
  // const [selectedOption, setSelectedOption] = useState(value)
  const [query, setQuery] = useState('')

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) =>
          option
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )

  const handleSelect = useCallback(
    (option: string) => {
      let newValue = ''
      // if (option === selectedOption) {
      if (option === value) {
        newValue = ''
      } else {
        newValue = option
      }
      // handleChange(newValue)
      // setSelectedOption(newValue)
      handleChange(newValue)
    },
    [value, handleChange]
    // [selectedOption, handleChange]
  )

  return (
    <div className="py-2">
      <Combobox value={value} onChange={handleSelect} disabled={disabled}>
        <div className="relative">
          <div className="relative w-full">
            {label && (
              <label className="block text-sm text-gray-700 mb-2">
                {label}
              </label>
            )}
            <Combobox.Input
              className="border-2 w-full text-slate-900 text-sm rounded-md p-3 pr-8 border-gray-300 hover:border-blue-400 focus:border-blue-500"
              onChange={(event) => setQuery(event.target.value)}
              placeholder={placeholder}
              required={required}
            />
            <Combobox.Button className="absolute bottom-3.5 right-0 pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Combobox.Options className="absolute border-2 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg focus:outline-none border-gray-300">
            {filteredOptions.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-500">
                Nothing found
              </div>
            ) : (
              filteredOptions.map((option, i) => (
                <Combobox.Option
                  key={`${option}_${i}`}
                  className={({ active, selected }) =>
                    `relative w-full cursor-pointer select-none py-2 pl-10 pr-4 bg-transparent text-slate-900 hover:bg-blue-500 hover:text-white ${
                      active ? 'bg-blue-500' : 'bg-transparent'
                    } ${selected ? 'bg-blue-200' : 'bg-white'}`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span className="text-sm">{option}</span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  )
}
