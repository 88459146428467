export const Footer = () => {
  return (
    <div className=" bg-gray-700 text-center text-[11px]">
      <span className="inline-block text-white p-4">
        <b className="inline-block text-white pr-8">MarketEPR v.1.0.0</b>{' '}
        Developed by{' '}
        <a
          href="https://www.studiovawter.com"
          className="border-b font-semibold border-transparent hover:border-white"
          rel="noreferrer"
          target="_blank"
        >
          Studio Vawter
        </a>
      </span>
    </div>
  )
}
