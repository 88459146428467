import { useCallback, useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { ComboBox } from '../../../components/forms/combo-box'
import { TextField } from '../../../components/forms/text-field'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { getProducts } from '../../../api/products/products'
import { AdminProductCard } from '../../../components/cards/admin-product-card'

const statusOption = ['available', 'sold']

export const ProductsPage = () => {
  const [isReady, setIsReady] = useState(false)
  const [products, setProducts] = useState([])
  const [queryTitle, setQueryTitle] = useState('')
  const [queryStatus, setQueryStatus] = useState('')

  const handleSearchChange = (q: string) => {
    setQueryTitle(q)
    debouncedSearch(q)
  }

  const debouncedSearch = useRef(
    debounce((q: string) => {
      if (q) {
        refetch(`title=${q}${queryStatus? `&status=${queryStatus}`:''}`)
      } else {
        refetch(`${queryStatus? `status=${queryStatus}`:''}`)
      }
    }, 300)
  ).current

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  const refetch = useCallback((queryParam: string = "") => {
    setIsReady(false)
      try {
        getProducts(
          queryParam,
          (response) => {
            setProducts(response.data)
            setIsReady(true)
          },
          (error) => {
            console.log(error)
            setIsReady(true)
          }
        )
      } catch (error) {
        console.error(error)
        setIsReady(true)
      }
  }, [])

  useEffect(() => {
    refetch()
  }, [refetch])

  const handleSelect = (q: string) => {
    setQueryStatus(q)
    if(q) {
      refetch(`status=${q}${queryTitle? `&title=${queryTitle}`:''}`)
    } else {
      refetch(`${queryTitle? `title=${queryTitle}`:''}`)
    }
  }

  const initFilter = () => {
    setQueryTitle("")
    setQueryStatus("")
    refetch()
  }

  return (
    <div className="flex-1 p-4 bg-slate-50 rounded-md min-h-[300px]">
      <div className="flex mb-4 gap-4">
        <div className="w-1/2">
          <TextField
            value={queryTitle}
            handleChange={handleSearchChange}
            placeholder="Search product"
          />
        </div>
        <div className="w-1/5">
          <ComboBox options={statusOption} placeholder='Select status' handleChange={handleSelect} value={queryStatus} />
        </div>
        {(queryTitle || queryStatus) && (
          <div className="w-1/6 self-center">
            <button type='button' className='bg-transparent hover:bg-gray-200 rounded-md cursor pointer p-4' onClick={() => initFilter()}><XMarkIcon className='w-4 h-4' /></button>
          </div>
        )}
      </div>
      {!isReady ? (
        <div className="text-center text-sm text-slate-500">Loading ..</div>
      ) : products.length !== 0 ? (
        products.map((product: any, i) => {
          return (
            <div key={i} className="flex mb-2">
              <div className="inline-block text-gray-800 text-sm self-center mr-4">
                {i + 1}
              </div>
              <div className="inline-block flex-1">
                <AdminProductCard product={product} />
              </div>
            </div>
          )
        })
      ) : (
        'No products found'
      )}
    </div>
  )
}
