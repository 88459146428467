import { CallBackError, CallBackResponse, createAxios } from '../createAxios'
import { AuthCredentials } from './types'

export const login = async (
  data: AuthCredentials,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  const api = createAxios()

  api
    .post('/auth/login', data)
    .then((response: any) => {
      cb(response ?? [])
    })
    .catch((error: any) => errCb(error))
}

export const refresh = async (
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  const api = createAxios()

  api
    .post('/auth/refresh-tokens', {
      refreshToken: tokens.refresh.token
    })
    .then((response: any) => {
      cb(response ?? [])
    })
    .catch((error: any) => errCb(error))
}
