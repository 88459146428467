import { Container } from '../layout/container'
import { useEffect, useRef, useState } from 'react'
import { TextField } from './text-field'
// import { ComboBox, OptionT } from './combo-box'
import { RangeSlider, RangeSliderValueT } from './range-slider'
import { LocationOptions, PlasticTypesOptions } from '../../constants/filters'
import { ComboBox } from './combo-box'
import { debounce } from 'lodash'

// const TOP_BAR_HEIGHT = '45px'
// const TOP_BAR_PY = '2rem'

const locationOptions = LocationOptions
const wasteTypeOptions = PlasticTypesOptions

const defaultRange = {
  min: 1000,
  max: 10000
}

type GalleryFilterProps = {
  updateSearchQuery?: (q: string) => void
}

export const GalleryFilter = ({ updateSearchQuery }: GalleryFilterProps) => {
  // const navigate = useNavigate()
  // const location = useLocation()

  // const searchParams = new URLSearchParams(location.search)
  // const queryValue = searchParams.get('q')

  // const [searchQuery, setSearchQuery] = useState('')
  const [title, setTitle] = useState('')
  const [location, setLocation] = useState('')
  const [plasticType, setPlasticType] = useState('')
  const [priceRange, setPriceRange] = useState<RangeSliderValueT>()
  // const [queryList, setQueryList] = useState<string[]>([])

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     // navigate(`?q=${searchQuery}`)
  //     console.log(`filters > {
  //       searchQuery: ${searchQuery},
  //       location: ${location},
  //       wasteType: ${wasteType},
  //       priceRange: ${priceRange}
  //     }`)
  //   }, 500)

  //   return () => clearTimeout(timer)
  // }, [searchQuery])

  // const handleSearchChange = (q: string) => {
  //   setTitle(q)
  //   if (updateSearchQuery) {
  //     if(q) {
  //       updateSearchQuery(`title=${q}`)
  //     } else {
  //       updateSearchQuery("")
  //     }
  //   }
  // }

  // const handleQueryChange = useCallback(
  //   (k: string, v: string) => {
  //     console.log('handleQueryChange > ', { k, v })

  //     switch (k) {
  //       case 'title':
  //         setTitle(v)
  //         break
  //       case 'location':
  //         setLocation(v)
  //         break
  //       case 'plasticType':
  //         setPlasticType(v)
  //         break
  //       default:
  //         setTitle(v)
  //         break
  //     }

  //     const searchQuery = []

  //     if (title) searchQuery.push(`title=${title}`)
  //     if (location) searchQuery.push(`location=${location}`)
  //     if (plasticType) searchQuery.push(`plasticType=${plasticType}`)

  //     console.log({ title, location, plasticType })
  //     console.log('searchQuery > ', searchQuery)

  //     if (updateSearchQuery) {
  //       if (searchQuery.length !== 0) {
  //         updateSearchQuery(searchQuery.join('&'))
  //       } else {
  //         updateSearchQuery('')
  //       }
  //     }
  //   },
  //   [title, location, plasticType, updateSearchQuery]
  // )

  const handleSearchChange = (q: string) => {
    setTitle(q)
    debouncedSearch(q)
  }

  const debouncedSearch = useRef(
    debounce((q: string) => {
      if (updateSearchQuery) {
        if(q) {
          updateSearchQuery(`title=${q}`)
        } else {
          updateSearchQuery("")
        }
      }
    }, 300)
  ).current

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  const handleLocationChange = (q: string) => {
    setLocation(q)
    if (updateSearchQuery) {
      if(q) {
        updateSearchQuery(`location=${q}`)
      } else {
        updateSearchQuery("")
      }
    }
  }

  const handlePlasticType = (q: string) => {
    setPlasticType(q)
    if (updateSearchQuery) {
      if(q) {
        updateSearchQuery(`plasticType=${q}`)
      } else {
        updateSearchQuery("")
      }
    }
  }

  // const updateProductList = () => {
  //   console.log('queryList > ', queryList)

  //   if (updateSearchQuery) {
  //     if (queryList.length !== 0) {
  //       updateSearchQuery(queryList.join('&'))
  //     } else {
  //       updateSearchQuery('')
  //     }
  //   }
  // }

  return (
    // <div
    //   className={`sticky top-[calc(${TOP_BAR_HEIGHT}+${TOP_BAR_PY})] bg-white shadow-md`}
    // >
    <div className={`sticky top-[calc(45px+2rem)] bg-gray-50 shadow-md z-10`}>
      <Container>
        <div className="py-4 w-full flex flex-row gap-4">
          <div className="grow-[7]">
            <TextField
              value={title}
              placeholder="What are you looking for?"
              // handleChange={(value) => handleQueryChange('title', value)}
              handleChange={(value) => handleSearchChange(value)}
            />
          </div>
          <div className="grow-[2]">
            <ComboBox
              options={locationOptions}
              value={location}
              // handleChange={(v: string) => handleQueryChange("location", v)}
              handleChange={(v: string) => handleLocationChange(v)}
              placeholder="Location"
            />
          </div>
          <div className="grow-[2]">
            <ComboBox
              options={wasteTypeOptions}
              value={plasticType}
              handleChange={(v: string) => handlePlasticType(v)}
              // handleChange={(v: string) => handleQueryChange('plasticType', v)}
              placeholder="Waste Type"
            />
          </div>
          <div className="grow-[2]">
            <RangeSlider
              handleChange={(range) => setPriceRange(range)}
              value={priceRange}
              min={defaultRange['min']}
              max={defaultRange['max']}
              placeholder="Price Range"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}
