import { FormEvent, useState } from 'react'
import { TextField } from './text-field'
import { TextArea } from './text-area'
import { FileUpload } from './file-upload'
import { createProduct } from '../../api/products/products'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ComboBox } from './combo-box'
import { LocationOptions, PlasticTypesOptions } from '../../constants/filters'
import { NumberField } from './number-field'
import { useSelector } from 'react-redux'

const plasticTypeOptions = PlasticTypesOptions
const locationOptions = LocationOptions

type ProductFormProps = {
  updateList: () => void
}

export const ProductForm = ({ updateList }: ProductFormProps) => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)
  const [title, setTitle] = useState('')
  const [cost, setCost] = useState<any>()
  const [weight, setWeight] = useState<any>()
  const [plasticType, setPlasticType] = useState('')
  const [location, setLocation] = useState('')
  const [description, setDescription] = useState('')
  const [imageSrc, setImageSrc] = useState<any>('')
  const [isLoading, setIsLoading] = useState(false)

  const initForm = () => {
    setTitle('')
    setCost('')
    setWeight('')
    setPlasticType('')
    setLocation('')
    setDescription('')
    setImageSrc(undefined)
  }

  const submitForm = (e: FormEvent) => {
    e.preventDefault()
    setIsLoading(true)

    const payload = new FormData()
    if (imageSrc) payload.append('photo', imageSrc[0])
    payload.append('title', title)
    payload.append('cost', cost!.toString())
    payload.append('weight', weight!.toString())
    payload.append('plasticType', plasticType)
    payload.append('location', location)
    payload.append('description', description)

    createProduct(
      payload,
      loggedInUser.tokens,
      (response) => {
        toast.success('Successfully created a product!', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        console.log(response)
        initForm()
        updateList()
        setIsLoading(false)
      },
      (error) => {
        console.log(error)
        toast.error('Failed to create a product. Please try again later.', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        //   setErrMsg('Something went wrong. Please try again later.')
        setIsLoading(false)
      }
    )
  }

  return (
    <>
      <form
        className="p-2 grid grid-cols-2 gap-4"
        onSubmit={(e: FormEvent) => submitForm(e)}
      >
        <div className="col-span-2">
          <TextField
            handleChange={setTitle}
            value={title}
            disabled={isLoading}
            label="Title"
            required
          />
        </div>
        <div className="col-span-2">
          <TextArea
            handleChange={setDescription}
            value={description}
            label="Description"
            disabled={isLoading}
            required
          />
        </div>
        <div className="col-span-2 z-10">
          <ComboBox
            options={plasticTypeOptions}
            handleChange={(v: string) => setPlasticType(v)}
            value={plasticType}
            label="Plastic Type"
            disabled={isLoading}
            required
          />
        </div>
        <div className="col-span-2">
          <ComboBox
              options={locationOptions}
              handleChange={(v: string) => setLocation(v)}
              value={location}
              label="Location"
              disabled={isLoading}
              required
            />
        </div>
        <NumberField
          handleChange={setWeight}
          value={weight}
          label="Weight (in kg)"
          required
        />
        <NumberField handleChange={setCost} value={cost} label="Price (in Php)" required />
        <div className="col-span-2">
          <FileUpload
            handleChange={setImageSrc}
            value={imageSrc}
            label="Upload image"
            disabled={isLoading}
            required
          />
        </div>
        <div className="col-span-2">
          <button
            type="submit"
            disabled={isLoading}
            className={`block text-sm font-semibold p-3 rounded text-center ${
              isLoading
                ? 'bg-blue-300 cursor-wait'
                : 'bg-blue-500 hover:bg-blue-300 active:bg-blue-700 cursor-pointer shadow-md active:shadow-none'
            }  text-slate-50 mt-4 w-full`}
          >
            {isLoading ? 'Please wait' : 'SUBMIT'}
          </button>
        </div>
      </form>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}
