export const Locations = [
  { id: 1, name: 'Ipil' },
  { id: 2, name: 'Mahayahay' },
  { id: 3, name: 'Catmon' },
  { id: 4, name: 'Dolores' },
  { id: 5, name: 'Salvacion' },
  { id: 6, name: 'Nueva Vista' },
  { id: 7, name: 'San Pablo' },
  { id: 8, name: 'San Isidro' },
  { id: 9, name: 'Valencia' }
]
export const LocationOptions = [
  'Ipil, Ormoc City',
  'Mahayahay, Ormoc City',
  'Catmon, Ormoc City',
  'Dolores, Ormoc City',
  'Salvacion, Ormoc City',
  'Nueva Vista, Ormoc City',
  'San Pablo, Ormoc City',
  'San Isidro, Ormoc City',
  'Valencia, Ormoc City'
]

export const PlasticTypes = [
  {
    id: 1,
    name: 'Polyethylene Terephthalate Plastic Type (PET or PETE or Polyester)'
  },
  { id: 2, name: 'High-Density Polyethylene Plastic Type (HDPE)' },
  { id: 3, name: 'Polyvinyl Chloride Plastic Type (PVC)' },
  { id: 4, name: 'Low-Density Polyethylene Plastic Type (LDPE)' },
  { id: 5, name: 'Polypropylene Plastic Type (PP)' },
  { id: 6, name: 'Polystyrene Plastic Type (PS)' },
  { id: 7, name: 'Other' }
]

export const PlasticTypesOptions = [
  'Polyethylene Terephthalate Plastic Type (PET or PETE or Polyester)',
  'High-Density Polyethylene Plastic Type (HDPE)',
  'Polyvinyl Chloride Plastic Type (PVC)',
  'Low-Density Polyethylene Plastic Type (LDPE)',
  'Polypropylene Plastic Type (PP)',
  'Polystyrene Plastic Type (PS)',
  'Other'
]
