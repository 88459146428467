import { CallBackError, CallBackResponse, createAxios } from '../createAxios'

const api = createAxios()

export const getUsers = async (
  queryParam: string,
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  api
    .get(`/users${queryParam? "?" + queryParam:""}`, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}

export const getUserProducts = async (
  uid: string,
  query: string = '',
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  api
    .get(`/users/${uid}/products${query? `?${query}`:''}`, {
      headers: {
        Authorization: `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}
