import { Outlet } from 'react-router-dom'
import { TopBar } from '../../components/topbar'
import { Footer } from '../../components/footer'
import { useSelector } from 'react-redux'

export const MainPage = () => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)

  return (
    <div className="min-w-full">
      <TopBar name={loggedInUser ? loggedInUser.user.username : ''} role={loggedInUser ? loggedInUser.user.role : ''} />
      <div className="min-h-screen bg-slate-200 pb-24">
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}
