import { useCallback, useEffect, useState } from 'react'
import { ProductCard, ProductCardT } from '../../components/cards/product-card'
import { GalleryFilter } from '../../components/filter'
import { Container } from '../../components/layout/container'
import { AxiosError, AxiosResponse } from 'axios'
import { getProducts } from '../../api/products/products'

export const GalleryPage = () => {
  const [ready, setReady] = useState(false)
  const [products, setProducts] = useState<ProductCardT[]>([])
  const [filteredProducts, setFilteredProducts] =
    useState<ProductCardT[]>(products)

  const fetchProducts = useCallback((q: string = '') => {
    getProducts(q,
      (response: AxiosResponse) => {
        setProducts(response.data)
        setFilteredProducts(response.data)
        setReady(true)
      },
      (error: AxiosError) => {
        console.log(error)
        setReady(true)
      }
    )
  }, [])

  useEffect(() => {
    fetchProducts()
  }, [fetchProducts])
  
  return (
    <div className="z-0">
      <GalleryFilter updateSearchQuery={(q) => fetchProducts(q)} />
      <Container>
        <div className="min-h-[1000px] py-4 w-full">
          {!ready ? (
            <div className="text-md text-gray-700">Loading ..</div>
          ) : (
            <>
              {filteredProducts && filteredProducts.length !== 0 ? (
                <div className="grid grid-flow-dense w-full gap-4 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
                  {filteredProducts.map((product, index) => {
                    return (
                      <div key={`${index}__${product.id}`}>
                        <ProductCard product={product} />
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div>No products to show</div>
              )}
            </>
          )}
        </div>
      </Container>
    </div>
  )
}
