import { Container } from '../../components/layout/container'
import { ProductForm } from '../../components/forms/product-form'
import { useCallback, useEffect, useState } from 'react'
import { getUserProducts } from '../../api/user/user'
import { ProductListItem } from '../../components/cards/product-list-item'
import { deleteProductById } from '../../api/products/products'
import { useSelector } from 'react-redux'

export const DashboardPage = () => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)
  const [products, setProducts] = useState([])
  const [isReady, setIsReady] = useState(false)

  const deleteProduct = (productId: string) => {
    deleteProductById(
      productId,
      loggedInUser.tokens,
      (response) => {
        console.log(response)
        refetch()
      },
      (error) => {
        console.log(error)
        refetch()
      }
    )
  }

  const refetch = useCallback(() => {
    try {
      getUserProducts(
        loggedInUser.user.id,
        'includeEpr=true',
        loggedInUser.tokens,
        (response) => {
          setProducts(response.data)
          setIsReady(true)
        },
        (error) => {
          console.log(error)
          setIsReady(true)
        }
      )
    } catch (error) {
      console.error(error)
      setIsReady(true)
    }
  }, [loggedInUser])

  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <div className="bg-slate-200 z-0">
      <Container>
        <div className="py-4 flex min-h-screen gap-4">
          <div className="w-1/3">
            <div className="p-4 bg-slate-50 rounded-md">
              <div className="text-lg font-bold text-center py-4">
                ADD PRODUCT
              </div>
              <div>
                <ProductForm updateList={() => refetch()} />
              </div>
            </div>
          </div>
          <div className="w-2/3">
            <div className="p-4 bg-slate-50 rounded-md min-h-[500px]">
              <div className="font-bold text-lg py-4 text-center">
                MY PRODUCTS
              </div>
              {!isReady ? (
                <div className="text-center text-sm text-slate-500">
                  Loading ..
                </div>
              ) : products.length !== 0 ? (
                products.map((product: any, i) => {
                  return (
                    <div
                      key={`${product.id}_${i}`}
                      className="flex flex-row my-2"
                    >
                      <div className="self-center text-left min-w-[30px] mx-4">
                        {i + 1}
                      </div>
                      <div className="self-center flex-1">
                        <ProductListItem
                          product={product}
                          remove={(id) => deleteProduct(id)}
                          refresh={() => refetch()}
                        />
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className="text-center text-sm text-slate-500">
                  No products to show
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
