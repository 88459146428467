import moment from "moment";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import { refresh } from "../api/auth/login";
import { setUser } from "../store/loggedInUserReducer";

export const Protected = (props: any) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loggedInUser = useSelector((state: any) => state.loggedInUser);

  const navigateToLogin = useCallback(() => {
    navigate('/auth/login')
    dispatch(setUser(null))
  }, [navigate, dispatch]);

  useEffect(() => {
    if (loggedInUser) {
      const role = loggedInUser.user.role

      const accessExpired = moment(loggedInUser.tokens.access.expires) < moment()
      
      if (accessExpired) {
        const refreshExpired = moment(loggedInUser.tokens.refresh.expires) < moment()

        if (refreshExpired) {
          navigateToLogin()
        } else {
          refresh(
            loggedInUser.tokens,
            (response) => {
              dispatch(setUser({
                ...loggedInUser,
                tokens: response.data
              }))
            },
            (err) => {
              console.log('error')
              navigateToLogin()
            }
          )
        }
      } else {
        // if logged in user's role does not match the expected role of the page
        if (role !== props.expectedRole) {
          // redirect to default dashboard of the user's role
          navigate('/')
        }
      }
    } else {
      navigateToLogin()
    }
  }, [dispatch, navigate, navigateToLogin, props, loggedInUser])
  return (
    <>
      { props.children }
    </>
  )
}
