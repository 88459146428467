import { NavLink, Outlet } from 'react-router-dom'
import { Container } from '../../../components/layout/container'
import {
  CubeIcon,
  PresentationChartBarIcon,
  UsersIcon
} from '@heroicons/react/24/outline'
// import { useEffect, useState } from 'react'

// const ACCESS_TOKEN_PATH =
//   process.env.REACT_APP_ACCESS_TOKEN_PATH ?? 'marketplace_client'
// const accessToken = window.localStorage.getItem(ACCESS_TOKEN_PATH)

export const AdminPage = () => {
  //   const [products, setProducts] = useState([])
  //   const [users, setUsers] = useState([])
  //   const [isReady, setIsReady] = useState(false)
  const linkStyle = ({ isActive }: { isActive: boolean }) =>
    `block p-3 text-sm cursor-pointer ${
      isActive
        ? 'bg-primary text-white'
        : 'bg-transparent hover:bg-slate-300 active:bg-slate-400'
    }`

  return (
    <div className="bg-slate-200 z-0">
      <Container>
        <div className="py-4 flex min-h-screen gap-4">
          <div className="w-[250px]">
            <div className="bg-slate-50 rounded overflow-hidden border">
              <NavLink
                to="/admin/dashboard"
                className={linkStyle}
                style={{ borderBottomWidth: '1px' }}
              >
                <PresentationChartBarIcon className="inline-block w-5 h-5 mr-2 -mt-1" />{' '}
                Dashboard
              </NavLink>
              <NavLink
                to="/admin/products"
                className={linkStyle}
                style={{ borderBottomWidth: '1px' }}
              >
                <CubeIcon className="inline-block w-5 h-5 mr-2 -mt-1" />
                Products
              </NavLink>
              <NavLink to="/admin/users" className={linkStyle}>
                <UsersIcon className="inline-block w-5 h-5 mr-2 -mt-1" /> Users
              </NavLink>
            </div>
          </div>
          {/* <div className="flex-1 p-4 bg-slate-50 rounded-md min-h-[500px]">
            A
          </div> */}
          <Outlet />
        </div>
      </Container>
    </div>
  )
}
