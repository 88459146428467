import { CallBackError, CallBackResponse, createAxios } from '../createAxios'
import { SignUpForm } from './types'

export const signup = async (
  data: SignUpForm,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  const api = createAxios()

  api
    .post('/auth/register', data)
    .then((response: any) => {
      cb(response ?? null)
    })
    .catch((error: any) => errCb(error))
}
