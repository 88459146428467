import { useEffect, useState } from 'react'
import { Navigate, Outlet, useNavigate, useOutlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const AuthPage = () => {
  // const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const outlet = useOutlet()
  const navigate = useNavigate()
  const loggedInUser = useSelector((state: any) => state.loggedInUser)

  useEffect(() => {
    if (loggedInUser) {
      navigate('/')
    }

    setIsReady(true)
  }, [navigate, loggedInUser])

  return isReady? (
    outlet ? (
        <div className="min-h-screen min-w-full bg-slate-200">
          <div className="container min-h-screen px-4 mx-auto">
            <Outlet />
          </div>
        </div>
    ) : (
      <Navigate to="/404" />
    )
  ):(
    <></>
  )
}
