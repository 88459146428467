import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'

export type UserT = {
  id: string
  username: string
  fullName: string
  contactNumber: string
  role: string
}

export type UserCardProps = {
  user: UserT
}

export const AdminUserCard = ({ user }: UserCardProps) => {
  const [open, setOpen] = useState(false)
  const { username, fullName, contactNumber, role } = user

  return (
    <>
      <div
        className="bg-gray-50 p-4 flex justify-between rounded-md cursor-pointer hover:bg-gray-200 border border-gray-300"
        onClick={() => setOpen(true)}
      >
        <span className="inline-block self-center">{fullName}</span>
        <ArrowTopRightOnSquareIcon className="inline-block self-center w-4 h-4" />
      </div>
      {open && (
        <div
          className="fixed h-screen w-screen bg-black top-0 left-0 z-20 opacity-80"
          onClick={() => setOpen(false)}
        ></div>
      )}
      <div
        className={`fixed top-0 w-1/3 min-h-screen z-30 bg-gray-50 p-10 flex flex-col duration-200 shadow-lg break-normal gap-5 ${
          open ? 'right-0' : '-right-1/3'
        }`}
      >
        <div className="text-right">
          <XMarkIcon
            className="h-8 w-8 cursor-pointer inline-block"
            onClick={() => setOpen(false)}
          />
        </div>
        <div className='text-left flex-row gap-4'>
            <div className='mb-8'>
                <div className='text-sm text-gray-600'>
                    NAME
                </div>
                <div className='text-3xl font-bold text-slate-900 break-words'>
                    {fullName}
                </div>
            </div>
            <div className='mb-4'>
                <div className='text-sm text-gray-600'>
                    USERNAME
                </div>
                <div className='text-xl text-slate-900'>
                    {username}
                </div>
            </div>
            <div className='mb-4'>
                <div className='text-sm text-gray-600'>
                    CONTACT NUMBER
                </div>
                <div className='text-xl text-slate-900'>
                    {contactNumber}
                </div>
            </div>
            <div className='mb-4'>
                <div className='text-sm text-gray-600'>
                    ROLE
                </div>
                <div className='text-xl text-slate-900'>
                    {role}
                </div>
            </div>
        </div>
      </div>
    </>
  )
}
