type NumberParams = {
  handleChange: (value: string) => void
  value?: number
  placeholder?: string
  label?: string
  required?: boolean
  disabled?: boolean
}

export const NumberField = ({
  handleChange,
  value = undefined,
  placeholder = '',
  label = '',
  required = false,
  disabled = false
}: NumberParams) => {
  return (
    <div className="py-2">
      {label && (
        <label className="block text-sm text-gray-700 mb-2">{label}</label>
      )}
      <input
        type="number"
        placeholder={placeholder}
        className="border-2 w-full text-slate-900 text-sm rounded-md p-3 border-gray-300 hover:border-blue-400 focus:border-blue-500"
        value={value}
        onChange={(event) => handleChange(event?.currentTarget.value)}
        required={required}
        disabled={disabled}
      />
    </div>
  )
}
