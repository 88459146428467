import { Menu, Dialog, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { Fragment, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { setUser } from '../../store/loggedInUserReducer'

const LogoutModal = ({
  isOpen,
  confirm,
  cancel
}: {
  isOpen: boolean
  confirm: () => void
  cancel: () => void
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={cancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
              <Dialog.Title
                as="h3"
                className="text-lg text-center font-medium leading-6 text-gray-900"
              >
                Are you sure you want to logout?
              </Dialog.Title>
              <div className="mt-4 flex flex-column gap-4 justify-center">
                <button
                  type="button"
                  className="inline-block text-sm font-semibold p-3 px-5 rounded text-center bg-transparent hover:bg-gray-300 active:bg-gray-500 cursor-pointer text-slate-900"
                  onClick={cancel}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="inline-block text-sm font-semibold p-3 px-5 rounded text-center bg-red-700 hover:bg-red-500 active:bg-red-900 cursor-pointer shadow-md active:shadow-none text-slate-50"
                  onClick={confirm}
                >
                  CONFIRM
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export const Account = ({ name, role }: { name: string; role: string }) => {
  const dispatch = useDispatch()
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
  const logout = () => {
    dispatch(setUser(null))
  }

  return (
    <>
      <Menu>
        {({ open }) => (
          <div>
            <Menu.Button
              className={`${open ? "bg-slate-600":'bg-transparent'} ${
                name ? 'items-center justify-between min-w-[170px]' : ''
              } py-2 px-4 rounded box-border max-w-[250px] flex cursor-pointer hover:bg-slate-600 active:bg-primary`}
            >
              {name && (
                <div className="text-white inline-block text-md mr-4 truncate">
                  Hi, {name}
                </div>
              )}
              <div className="text-white inline-block">
                <ChevronDownIcon className="w-4 h-4" />
              </div>
            </Menu.Button>
            <div
              className={`${
                open ? 'absolute' : 'hidden'
              } bg-slate-50 shadow-md rounded min-w-[150px] border translate-y-full p-2 -top-6 text-sm z-50`}
            >
              <Menu.Items as="div">
                {role === 'seller' && (
                  <Menu.Item>
                    <Link
                      to="/dashboard"
                      className="block p-2 bg-transparent hover:bg-slate-200 active:bg-slate-300 rounded cursor-pointer text-sm"
                    >
                      Products
                    </Link>
                  </Menu.Item>
                )}
                {role === 'admin' && (
                  <Menu.Item>
                    <Link
                      to="/admin"
                      className="block p-2 bg-transparent hover:bg-slate-200 active:bg-slate-300 rounded cursor-pointer text-sm"
                    >
                      Manage
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Item
                  as="div"
                  className="p-2 bg-transparent hover:bg-red-500 active:bg-red-600 hover:text-slate-50 rounded cursor-pointer text-sm"
                  onClick={() => setIsLogoutModalOpen(true)}
                >
                  Log Out
                </Menu.Item>
              </Menu.Items>
            </div>
          </div>
        )}
      </Menu>
      <LogoutModal
        isOpen={isLogoutModalOpen}
        confirm={() => logout()}
        cancel={() => setIsLogoutModalOpen(false)}
      />
    </>
  )
}
