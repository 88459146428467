import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import {
  BanknotesIcon,
  MapPinIcon,
  ScaleIcon,
  XMarkIcon
} from '@heroicons/react/24/solid'
import { useState } from 'react'

import PLACEHOLDER_IMG from './../../assets/images/placeholder.png'

type SellerT = {
  contactNumber: string
  fullName: string
  role: string
  username: string
}

export type ProductCardT = {
  id: string
  title: string
  imgSrc?: string
  status?: string
  weight: number
  cost: number
  location: string
  description?: string
  createdAt: string
  user: SellerT
}

export interface AdminProductCardProps {
    product: ProductCardT
  }

export const AdminProductCard = ({ product }: AdminProductCardProps) => {
  const [open, setOpen] = useState(false)
  const {
    title,
    imgSrc,
    status,
    weight,
    cost,
    location,
    description,
    user  } = product

  return (
    <>
      <div
        className="bg-gray-50 p-4 flex justify-between rounded-md cursor-pointer hover:bg-gray-200 border border-gray-300"
        onClick={() => setOpen(true)}
      >
        <span className="inline-block self-center">{title}</span>
        <div className='text-right flex gap-4'>
            {status === 'sold' ? (
            <span className="font-bold text-sm text-red-500 self-center">
              SOLD
            </span>
          ) : (
            <span className="font-bold text-sm text-green-500 self-center">
              AVAILABLE
            </span>
          )}
            <ArrowTopRightOnSquareIcon className="inline-block self-center w-4 h-4" />
        </div>
      </div>
      {open && (
        <div
          className="fixed h-screen w-screen bg-black top-0 left-0 z-20 opacity-80"
          onClick={() => setOpen(false)}
        ></div>
      )}
      <div
        className={`fixed top-0 w-1/3 h-screen z-30 bg-gray-50 p-10 flex flex-col duration-200 shadow-lg break-normal gap-5 ${
          open ? 'right-0' : '-right-1/3'
        }`}
      >
        <div className="flex justify-between">
          {status === 'sold' ? (
            <span className="font-bold text-xl text-red-500 self-center">
              SOLD
            </span>
          ) : (
            <span className="font-bold text-xl text-green-500 self-center">
              AVAILABLE
            </span>
          )}
          <XMarkIcon
            className="h-8 w-8 cursor-pointer inline-block"
            onClick={() => setOpen(false)}
          />
        </div>
        <div
          className="w-100 h-[250px] bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${imgSrc ? imgSrc : PLACEHOLDER_IMG})`,
            filter: status === 'sold' ? 'grayscale(100%)' : ''
          }}
        ></div>
        <div>
          <h1 className="text-xl text-gray-900 mb-2">{title}</h1>
          <p className="text-md text-gray-700">
            {description ? description : '-'}
          </p>
        </div>
        <div>
          <div className="text-md truncate">
            <MapPinIcon className="text-gray-400 h-4 inline-block mr-2 truncate" />
            <span>{location}</span>
          </div>
          <div className="text-md truncate">
            <ScaleIcon className="text-gray-400 h-4 inline-block mr-2 truncate" />
            <span>{weight.toFixed(2)} kg</span>
          </div>
          <div className="text-md truncate">
            <BanknotesIcon className="text-gray-400 h-4 inline-block mr-2 truncate" />
            <span>Php {cost.toFixed(2)}</span>
          </div>
        </div>
        <div>
          <div className="border-dashed border-2 border-blue-500 p-4 rounded-lg">
            <div className="text-center mb-2 uppercase">
              Seller's Information
            </div>
            <div>
              Name: <b>{user.fullName}</b>
            </div>
            <div>
              Username: <b>{user.username}</b>
            </div>
            <div>
              Contact No.: <b>{user.contactNumber}</b>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
