type FileUploadParams = {
  handleChange: (value: any) => void
  value?: string | undefined
  label?: string
  required?: boolean
  disabled?: boolean
}

export const FileUpload = ({
  handleChange,
  value = undefined,
  label = '',
  required = false,
  disabled = false
}: FileUploadParams) => {
  return (
    <div className="py-2">
      {label && (
        <label className="block text-sm text-gray-700 mb-2">{label}</label>
      )}
      <input
        type="file"
        className="border-2 w-full text-slate-900 text-sm rounded-md p-3 border-gray-300 hover:border-blue-400 focus:border-blue-500 cursor-pointer"
        name={value}
        onChange={(event) => handleChange(event?.target.files)}
        required={required}
        disabled={disabled}
      />
    </div>
  )
}
