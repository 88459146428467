import { useCallback, useEffect, useRef, useState } from 'react'
import { getUsers } from '../../../api/user/user'
import { AdminUserCard, UserT } from '../../../components/cards/admin-user-card'
import { debounce } from 'lodash'
import { ComboBox } from '../../../components/forms/combo-box'
import { TextField } from '../../../components/forms/text-field'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { useSelector } from 'react-redux'

const roleOptions = ['seller', 'admin']

export const UsersPage = () => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)
  const [isReady, setIsReady] = useState(false)
  const [users, setUsers] = useState([])
  const [queryName, setQueryName] = useState('')
  const [queryRole, setQueryRole] = useState('')

  const handleSearchChange = (q: string) => {
    setQueryName(q)
    debouncedSearch(q)
  }

  const debouncedSearch = useRef(
    debounce((q: string) => {
      if (q) {
        refetch(`fullName=${q}${queryRole? `&role=${queryRole}`:''}`)
      } else {
        refetch(`${queryRole? `role=${queryRole}`:''}`)
      }
    }, 300)
  ).current

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  const refetch = useCallback((queryParam: string = "") => {
    setIsReady(false)
      try {
        getUsers(
          queryParam,
          loggedInUser.tokens,
          (response) => {
            setUsers(response.data)
            setIsReady(true)
          },
          (error) => {
            console.log(error)
            setIsReady(true)
          }
        )
      } catch (error) {
        console.error(error)
        setIsReady(true)
      }
  }, [loggedInUser])

  useEffect(() => {
    refetch()
  }, [refetch])

  const handleSelect = (q: string) => {
    setQueryRole(q)
    if(q) {
      refetch(`role=${q}${queryName? `&fullName=${queryName}`:''}`)
    } else {
      refetch(`${queryName? `fullName=${queryName}`:''}`)
    }
  }

  const initFilter = () => {
    setQueryName("")
    setQueryRole("")
    refetch()
  }

  return (
    <div className="flex-1 p-4 bg-slate-50 rounded-md min-h-[300px]">
      <div className="flex mb-4 gap-4">
        <div className="w-1/2">
          <TextField
            value={queryName}
            handleChange={handleSearchChange}
            placeholder="Search user"
          />
        </div>
        <div className="w-1/5">
          <ComboBox options={roleOptions} placeholder='Select role' handleChange={handleSelect} value={queryRole} />
        </div>
        {(queryName || queryRole) && (
          <div className="w-1/6 self-center">
            <button type='button' className='bg-transparent hover:bg-gray-200 rounded-md cursor pointer p-4' onClick={() => initFilter()}><XMarkIcon className='w-4 h-4' /></button>
          </div>
        )}
      </div>
      {!isReady ? (
        <div className="text-center text-sm text-slate-500">Loading ..</div>
      ) : users.length !== 0 ? (
        users.map((user: UserT, i) => {
          return (
            <div key={i} className="flex mb-2">
              <div className="inline-block text-gray-800 text-sm self-center mr-4">
                {i + 1}
              </div>
              <div className="inline-block flex-1">
                <AdminUserCard user={user} />
              </div>
            </div>
          )
        })
      ) : (
        'No users found'
      )}
    </div>
  )
}
