import { CallBackError, CallBackResponse, createAxios } from '../createAxios'

const api = createAxios()

export const getProducts = async (
  query: string,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  const url = `/products${query !== ""? "?"+query:""}`
  api
    .get(url)
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}

export const createProduct = async (
  payload: any,
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  api
    .post('/products', payload, {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}

export const updateProduct = async (
  id: string,
  payload: any,
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  api
    .patch(`/products/${id}`, payload, {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}

export const deleteProductById = async (
  id: string,
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  api
    .delete(`/products/${id}`, {
      headers: {
        'Authorization': `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}

export const createEPR = async (
  payload: any,
  tokens: any,
  cb: CallBackResponse,
  errCb: CallBackError
) => {
  api
    .post('/eprs', payload, {
      headers: {
        'Authorization': `Bearer ${tokens.access.token}`
      }
    })
    .then((response) => cb(response ?? []))
    .catch((error) => errCb(error))
}