import {
  BanknotesIcon,
  MapPinIcon,
  ScaleIcon,
  XMarkIcon
} from '@heroicons/react/24/solid'
import PLACEHOLDER_IMG from './../../assets/images/placeholder.png'
import { useState } from 'react'

type SellerT = {
  contactNumber: string
  fullName: string
  role: string
  username: string
}

export type ProductCardT = {
  id: string
  title: string
  imgSrc?: string
  status?: string
  weight: number
  cost: number
  location: string
  description?: string
  createdAt: string
  user: SellerT
  plasticType: string
}

export interface ProductCardProps {
  product: ProductCardT
}

export const ProductCard = ({ product }: ProductCardProps) => {
  const [open, setOpen] = useState(false)
  
  const [openSellerInfo, setOpenSellerInfo] = useState(false)
  const {
    title,
    imgSrc,
    status,
    weight,
    cost,
    location,
    description,
    user,
    plasticType
  } = product

  const openCard = () => {
    if (product.status !== 'sold') setOpen(true)
  }

  return (
    <>
      <div
        className={`bg-gray-50 rounded-md overflow-hidden cursor-pointer transition duration-75 ease-linear group border border-gray-300 shadow-none ${
          product.status === 'sold'
            ? 'opacity-50'
            : 'hover:-translate-y-1 hover:shadow-md'
        }`}
        onClick={() => openCard()}
      >
        <div className="pb-2">
          <div
            className="w-100 h-[170px] bg-cover bg-center"
            style={{
              backgroundImage: `url(${imgSrc ? imgSrc : PLACEHOLDER_IMG})`,
              filter: status === 'sold' ? 'grayscale(100%)' : ''
            }}
          ></div>
          <div className="p-4 text-left">
            <div className="font-bold text-lg mb-1 truncate">
              {title}
              {status === 'sold' ? (
                <span className="text-red-500 ml-2">(SOLD)</span>
              ) : null}
            </div>
            <div className="text-sm truncate">
              <MapPinIcon className="text-gray-400 h-4 inline-block mr-2 truncate" />
              <span>{location}</span>
            </div>
            <div className="text-sm truncate">
              <ScaleIcon className="text-gray-400 h-4 inline-block mr-2 truncate" />
              <span>{weight.toFixed(2)} kg</span>
            </div>
            <div className="text-sm truncate">
              <BanknotesIcon className="text-gray-400 h-4 inline-block mr-2 truncate" />
              <span>Php {cost.toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <div
          className="fixed h-screen w-screen bg-black top-0 left-0 z-20 opacity-80"
          onClick={() => setOpen(false)}
        ></div>
      )}
      <div
        className={`fixed top-0 w-1/3 h-screen z-30 bg-gray-50 p-10 flex flex-col duration-200 break-normal gap-5 ${
          open ? 'right-0 shadow-lg' : '-right-1/3 shadow-none'
        }`}
      >
        <div className="flex justify-between">
          {status === 'sold' ? (
            <span className="font-bold text-xl text-red-500 self-center">
              SOLD
            </span>
          ) : (
            <span className="font-bold text-xl text-green-500 self-center">
              AVAILABLE
            </span>
          )}
          <XMarkIcon
            className="h-8 w-8 cursor-pointer inline-block"
            onClick={() => setOpen(false)}
          />
        </div>
        <div
          className="w-100 h-[250px] bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${imgSrc ? imgSrc : PLACEHOLDER_IMG})`,
            filter: status === 'sold' ? 'grayscale(100%)' : ''
          }}
        ></div>
        <div>
          <h1 className="text-xl text-gray-900 mb-2">{title}</h1>
          <p className="text-md text-gray-700">
            {description ? description : '-'}
          </p>
        </div>
        <div>
          <div className="text-md">
            <span>PLASTIC TYPE:</span><span className='inline-block pl-4 font-semibold '>{plasticType}</span>
          </div>
        </div>
        <div>
          <div className="text-md truncate">
            <MapPinIcon className="text-gray-400 h-4 inline-block mr-2 truncate" />
            <span>{location}</span>
          </div>
          <div className="text-md truncate">
            <ScaleIcon className="text-gray-400 h-4 inline-block mr-2 truncate" />
            <span>{weight.toFixed(2)} kg</span>
          </div>
          <div className="text-md truncate">
            <BanknotesIcon className="text-gray-400 h-4 inline-block mr-2 truncate" />
            <span>Php {cost.toFixed(2)}</span>
          </div>
        </div>
        <div>
          {openSellerInfo ? (
            <div className="border-dashed border-2 border-blue-500 p-4 rounded-lg">
              <div className="text-center mb-2 uppercase">
                Seller's Information
              </div>
              <div>
                Name: <b>{user.fullName}</b>
              </div>
              <div>
                Contact No.: <b>{user.contactNumber}</b>
              </div>
            </div>
          ) : (
            <button
              type="button"
              className="block text-sm font-semibold p-3 rounded text-center bg-blue-500 hover:bg-blue-300 active:bg-blue-700 cursor-pointer shadow-md text-slate-50 w-full"
              onClick={() => setOpenSellerInfo(true)}
            >
              CONTACT SELLER
            </button>
          )}
        </div>
      </div>
    </>
  )
}
