import {
  PencilSquareIcon,
  TrashIcon,
  XMarkIcon
} from '@heroicons/react/24/solid'
import PLACEHOLDER_IMG from './../../assets/images/placeholder.png'
import { FormEvent, Fragment, useState } from 'react'
import { Dialog, Switch, Transition } from '@headlessui/react'
import { toast } from 'react-toastify'
import { createEPR, updateProduct } from '../../api/products/products'
import { TextField } from '../forms/text-field'
import { TextArea } from '../forms/text-area'
import { ComboBox } from '../forms/combo-box'
import { LocationOptions, PlasticTypesOptions } from '../../constants/filters'
import { NumberField } from '../forms/number-field'
import { FileUpload } from '../forms/file-upload'
import { useSelector } from 'react-redux'

const plasticTypeOptions = PlasticTypesOptions
const locationOptions = LocationOptions

type EPRForm = {
  id: string
  obligedEnterprise: string
  transactingOrg: string
  contactPerson: string
  contactEmail: string
  contactNumber: string
  productId: string
}

type EPRFormEntry = {
  productId: string
  obligedEnterprise: string
  transactingOrg: string
  contactPerson: string
  contactEmail: string
  contactNumber: string
}

export type ProductListItemT = {
  id: string
  title: string
  imgSrc?: string
  status?: string
  weight: number
  cost: number
  location: string
  description?: string
  createdAt: string
  plasticType: string
  epr: EPRForm
}

export interface ProductListItemProps {
  product: ProductListItemT
  remove?: (productId: string) => void
  refresh: () => void
}

const DeleteDialog = ({
  productName,
  isOpen,
  confirm,
  cancel,
  isLoading = false
}: {
  productName: string
  isOpen: boolean
  confirm: () => void
  cancel: () => void
  isLoading: boolean
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={cancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all">
              <Dialog.Title
                as="h3"
                className="text-lg text-center font-medium leading-6 text-gray-900"
              >
                Are you sure you want to delete <b>{productName}</b>?
              </Dialog.Title>
              <div className="mt-4 flex flex-column gap-4 justify-center">
                <button
                  type="button"
                  className="inline-block text-sm font-semibold p-3 px-5 rounded text-center bg-transparent hover:bg-gray-300 active:bg-gray-500 cursor-pointer text-slate-900"
                  onClick={cancel}
                  disabled={isLoading}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className={`inline-block text-sm font-semibold p-3 px-5 rounded text-center text-slate-50 ${
                    isLoading
                      ? 'cursor-wait shadow-none bg-red-400'
                      : 'cursor-pointer bg-red-700 hover:bg-red-500 active:bg-red-900 shadow-md active:shadow-none'
                  }`}
                  onClick={confirm}
                  disabled={isLoading}
                >
                  {isLoading ? 'DELETING' : 'DELETE'}
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

const EPRFormDialog = ({
  productId,
  productName,
  isOpen,
  confirm,
  cancel,
  isLoading = false
}: {
  productId: string
  productName: string
  isOpen: boolean
  confirm: (form: EPRFormEntry) => void
  cancel: () => void
  isLoading: boolean
}) => {
  const [enterpriseName, setEnterpriseName] = useState('')
  const [pro, setPro] = useState('')
  const [contactPerson, setContactPerson] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactNumber, setContactNumber] = useState('')

  const submitForm = (e: FormEvent) => {
    e.preventDefault()

    confirm({
      productId: productId,
      obligedEnterprise: enterpriseName,
      transactingOrg: pro,
      contactPerson: contactPerson,
      contactEmail: contactEmail,
      contactNumber: contactNumber
    })
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={cancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Dialog.Panel className="w-full md:w-1/2 min-w-md transform overflow-hidden rounded-lg bg-white p-8 text-left align-middle shadow-xl transition-all">
              <form onSubmit={(e: FormEvent) => submitForm(e)}>
                <div className="flex justify-between mb-8">
                  <div className="font-bold text-2xl">EPR Form</div>
                  <XMarkIcon
                    className="h-8 w-8 cursor-pointer inline-block"
                    onClick={cancel}
                  />
                </div>
                <div className="w-full">
                  <div className="grid grid-cols-2 align-middle text-md mb-4">
                    <div className="self-center font-semibold">Product</div>
                    <div>{productName}</div>
                  </div>
                  <div className="grid grid-cols-2 align-middle text-md">
                    <div className="self-center font-semibold">
                      Name of Obliged Enterprise
                    </div>
                    <div>
                      <TextField
                        handleChange={setEnterpriseName}
                        value={enterpriseName}
                        disabled={isLoading}
                        required
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 align-middle text-md">
                    <div className="self-center font-semibold">
                      Transacting Organization (PRO)
                    </div>
                    <div>
                      <TextField
                        handleChange={setPro}
                        value={pro}
                        disabled={isLoading}
                        required
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 align-middle text-md">
                    <div className="self-center font-semibold">
                      Contact Person
                    </div>
                    <div>
                      <TextField
                        handleChange={setContactPerson}
                        value={contactPerson}
                        disabled={isLoading}
                        required
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 align-middle text-md">
                    <div className="self-center font-semibold">
                      Contact Email
                    </div>
                    <div>
                      <TextField
                        handleChange={setContactEmail}
                        value={contactEmail}
                        disabled={isLoading}
                        required
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 align-middle text-md">
                    <div className="self-center font-semibold">
                      Contact Number
                    </div>
                    <div>
                      <TextField
                        handleChange={setContactNumber}
                        value={contactNumber}
                        disabled={isLoading}
                        required
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 align-middle text-md">
                    <div></div>
                    <div className="pt-8 gap-4 flex justify-evenly">
                      <button
                        type="submit"
                        className={`inline-block text-sm font-semibold w-full p-3 px-5 rounded text-center text-slate-50 ${
                          isLoading
                            ? 'cursor-wait shadow-none bg-red-200'
                            : 'cursor-pointer bg-blue-500 hover:bg-blue-300 active:bg-blue-700 shadow-md active:shadow-none'
                        }`}
                        disabled={isLoading}
                      >
                        {isLoading ? 'Please wait' : 'SUBMIT'}
                      </button>
                      <button
                        type="button"
                        className="inline-block text-sm w-full font-semibold p-3 px-5 rounded text-center bg-transparent hover:bg-gray-300 active:bg-gray-500 cursor-pointer text-slate-900"
                        onClick={cancel}
                        disabled={isLoading}
                      >
                        CANCEL
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export const ProductListItem = ({
  product,
  remove = undefined,
  refresh
}: ProductListItemProps) => {
  const loggedInUser = useSelector((state: any) => state.loggedInUser)
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const {
    id,
    title,
    imgSrc,
    status,
    weight,
    cost,
    location,
    description,
    plasticType,
    epr
  } = product
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [enabled, setEnabled] = useState(status === 'available')
  const [updatingStatus, setUpdatingStatus] = useState(false)

  const [editTitle, setEditTitle] = useState(title)
  const [editDescription, setEditDescription] = useState(description ?? '')
  const [editPlasticType, setEditPlasticType] = useState(plasticType)
  const [editWeight, setEditWeight] = useState<any>(weight)
  const [editCost, setEditCost] = useState<any>(cost)
  const [editLocation, setEditLocation] = useState(location)
  const [editPhoto, setEditPhoto] = useState<any>('')

  const [toSold, setToSold] = useState(false)
  const [showEprForm, setShowEprForm] = useState(false)
  const [submittingEprForm, setSubmittingEprForm] = useState(false)

  const handleShowEprForm = () => {
    setOpen(false)
    setShowEprForm(true)
  }

  const confirmDelete = (productId: string) => {
    setIsLoading(true)
    try {
      if (remove) {
        remove(productId)
      }
      setShowDeleteDialog(false)
      toast.success('Successfully deleted a product!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    } catch (error) {
      console.log(error)
      setShowDeleteDialog(false)
      toast.error('Failed to delete a product. Please try again later.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
    }
    setIsLoading(false)
  }

  const submitUpdate = (e: FormEvent) => {
    e.preventDefault()
    setIsLoading(true)

    const payload = new FormData()
    if (editTitle !== title) payload.append('title', editTitle)
    if (editDescription !== description)
      payload.append('description', editDescription)
    if (editPlasticType !== plasticType)
      payload.append('plasticType', editPlasticType)
    if (editWeight !== weight) payload.append('weight', editWeight!.toString())
    if (editCost !== cost) payload.append('cost', editCost!.toString())
    if (editLocation !== location) payload.append('location', editLocation)
    if (editPhoto) payload.append('photo', editPhoto[0])

    updateProduct(
      id,
      payload,
      loggedInUser.tokens,
      (response) => {
        toast.success('Successfully updated a product!', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        console.log(response)
        refresh()
        setIsLoading(false)
      },
      (error) => {
        console.log(error)
        toast.error('Failed to update a product. Please try again later.', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        setIsLoading(false)
      }
    )
  }

  const toggleStatus = (value: string, withRefresh: boolean = true) => {
    setUpdatingStatus(true)
    const payload = new FormData()
    payload.append('status', value)

    updateProduct(
      id,
      payload,
      loggedInUser.tokens,
      (response) => {
        if (withRefresh) {
          refresh()
        }
        setUpdatingStatus(false)
        setEnabled(response.data.status === 'available')
        toast.success('Successfully updated product status!', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        console.log(response)
      },
      (error) => {
        console.log(error)
        setUpdatingStatus(false)
        setIsLoading(false)
      }
    )
  }

  const submitEprForm = (form: EPRFormEntry) => {
    setSubmittingEprForm(true)

    createEPR(
      form,
      loggedInUser.tokens,
      (response) => {
        toast.success('Successfully submitted EPR Form!', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        toggleStatus('sold')
        setSubmittingEprForm(false)
        setShowEprForm(false)
      },
      (error) => {
        console.log(error)
        setShowEprForm(false)
        setSubmittingEprForm(false)
      }
    )
  }

  return (
    <>
      <div className="bg-gray-50 rounded-md overflow-hidden cursor-pointer hover:bg-gray-200 group border border-gray-300">
        <div className="p-4 flex">
          <div
            className="w-1/2 truncate inline-block self-center"
            onClick={() => setOpen(true)}
          >
            {title}
          </div>
          <div className="w-1/2 inline-block text-right">
            {epr && (
              <div className="self-center cursor-default text-white bg-green-500 p-2 rounded-md text-xs inline-block mr-4">
                EPR
              </div>
            )}
            <div
              className={`py-2 px-4 rounded uppercase font-bold inline-block group-hover:hidden ${
                status === 'available' ? 'text-green-500' : 'text-red-500'
              }`}
            >
              {status}
            </div>
            <div className="hidden gap-2 group-hover:inline-flex">
              <button
                type="button"
                onClick={() => setOpen(true)}
                className="shadow-md inline-flex py-2 px-4 font-bold text-white bg-blue-500 hover:bg-blue-400 active:bg-blue-700 active:shadow-none rounded"
              >
                <PencilSquareIcon className="self-center w-4 h-4 mr-1" />
                <span className="self-center">EDIT</span>
              </button>
              <button
                type="button"
                onClick={() => setShowDeleteDialog(true)}
                className="shadow-md inline-flex py-2 px-4 font-bold text-white bg-red-500 hover:bg-red-400 active:bg-red-700 active:shadow-none rounded"
              >
                <TrashIcon className="w-4 h-4 self-center" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <div
          className="fixed h-screen w-screen bg-black top-0 left-0 z-20 opacity-80"
          onClick={() => setOpen(false)}
        ></div>
      )}
      <div
        className={`absolute top-0 w-1/3 min-h-screen z-30 bg-gray-50 p-10 flex flex-col duration-200 break-normal gap-5 ${
          open ? 'right-0 shadow-lg' : '-right-1/3 shadow-none'
        }`}
      >
        <div className="flex justify-between">
          <div>
            <Switch
              checked={enabled}
              onChange={() =>
                toggleStatus(status === 'available' ? 'sold' : 'available')
              }
              disabled={updatingStatus}
              className={`${enabled ? 'bg-green-500' : 'bg-gray-200'} ${
                updatingStatus ? 'bg-gray-500' : ''
              } relative inline-flex h-6 w-11 items-center rounded-full mr-4`}
            >
              <span className="sr-only">Enable notifications</span>
              <span
                className={`${
                  enabled ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
            {updatingStatus ? (
              <span className="font-bold text-xl text-gray-500 self-center">
                Updating status
              </span>
            ) : status === 'sold' ? (
              <span className="font-bold text-xl text-red-500 self-center">
                SOLD
              </span>
            ) : (
              <span className="font-bold text-xl text-green-500 self-center">
                AVAILABLE
              </span>
            )}
          </div>
          <XMarkIcon
            className="h-8 w-8 cursor-pointer inline-block"
            onClick={() => setOpen(false)}
          />
        </div>
        <div
          className="w-100 h-[250px] bg-cover bg-center rounded-lg"
          style={{
            backgroundImage: `url(${imgSrc ? imgSrc : PLACEHOLDER_IMG})`,
            filter: status === 'sold' ? 'grayscale(100%)' : ''
          }}
        ></div>
        <form
          className="grid grid-cols-2 gap-4"
          onSubmit={(e) => submitUpdate(e)}
        >
          <div className="col-span-2">
            <FileUpload
              handleChange={setEditPhoto}
              value={editPhoto}
              label="Change image"
              disabled={isLoading}
            />
          </div>
          <div className="col-span-2">
            <TextField
              handleChange={setEditTitle}
              value={editTitle}
              disabled={isLoading}
              label="Title"
              required
            />
          </div>
          <div className="col-span-2">
            <TextArea
              handleChange={setEditDescription}
              value={editDescription}
              label="Description"
              disabled={isLoading}
              required
            />
          </div>
          <div className="col-span-2 z-10">
            <ComboBox
              options={plasticTypeOptions}
              handleChange={setEditPlasticType}
              value={editPlasticType}
              label="Plastic Type"
              disabled={isLoading}
              required
            />
          </div>
          <div className="col-span-2">
            <ComboBox
              options={locationOptions}
              handleChange={setEditLocation}
              value={editLocation}
              label="Location"
              disabled={isLoading}
              required
            />
          </div>
          <NumberField
            handleChange={setEditWeight}
            value={editWeight}
            label="Weight (in kg)"
            required
          />
          <NumberField
            handleChange={setEditCost}
            value={editCost}
            label="Price (in Php)"
            required
          />
          <div className="col-span-2 flex gap-4 pt-8">
            <button
              type="submit"
              disabled={isLoading}
              className={`text-sm font-semibold py-4 rounded text-center ${
                isLoading
                  ? 'bg-blue-300 cursor-wait'
                  : 'bg-blue-500 hover:bg-blue-300 active:bg-blue-700 cursor-pointer shadow-md active:shadow-none'
              }  text-slate-50 w-full`}
            >
              {isLoading ? 'Please wait' : 'UPDATE'}
            </button>
            <button
              type="button"
              onClick={() => setShowDeleteDialog(true)}
              className="shadow-md inline-flex p-4 font-bold text-white bg-red-500 hover:bg-red-400 active:bg-red-700 active:shadow-none rounded"
              disabled={isLoading}
            >
              <TrashIcon className="w-4 h-4 self-center" />
            </button>
          </div>
        </form>
        <div>
          {status === 'sold' ? (
            null
          ) : toSold ? (
            <div className="p-4 border-dashed border-2 border-blue-500 rounded-lg">
              <div className="font-bold text-lg mb-8 text-center">
                Mark as EPR?
              </div>
              <div className="flex gap-4 justify-evenly">
                <button
                  type="button"
                  disabled={isLoading}
                  className="text-sm p-4 w-full font-semibold text-slate-900 bg-transparent hover:bg-gray-200 active:bg-gray-400 text-center active:shadow-none rounded"
                  onClick={() => toggleStatus('sold')}
                >
                  NO
                </button>
                <button
                  type="button"
                  disabled={isLoading}
                  className="shadow-md text-sm p-4 w-full font-semibold text-white bg-blue-500 hover:bg-blue-400 active:bg-blue-700 text-center active:shadow-none rounded"
                  onClick={() => handleShowEprForm()}
                >
                  YES
                </button>
              </div>
            </div>
          ) : (
            <button
              type="button"
              disabled={isLoading}
              className="shadow-md text-sm p-4 w-full font-semibold text-white bg-green-500 hover:bg-green-400 active:bg-green-700 text-center active:shadow-none rounded"
              onClick={() => setToSold(true)}
            >
              MARK AS SOLD
            </button>
          )}
        </div>
      </div>
      <DeleteDialog
        productName={title}
        isOpen={showDeleteDialog}
        isLoading={isLoading}
        confirm={() => confirmDelete(id)}
        cancel={() => setShowDeleteDialog(false)}
      />
      <EPRFormDialog
        productName={title}
        productId={id}
        isOpen={showEprForm}
        isLoading={submittingEprForm}
        confirm={(data: EPRFormEntry) => submitEprForm(data)}
        cancel={() => setShowEprForm(false)}
      />
    </>
  )
}
