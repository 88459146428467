type TextAreaParams = {
  handleChange: (value: string) => void
  value?: string
  placeholder?: string
  label?: string
  required?: boolean
  disabled?: boolean
}

export const TextArea = ({
  handleChange,
  value = '',
  placeholder = '',
  label = '',
  required = false,
  disabled = false
}: TextAreaParams) => {
  return (
    <div className="py-2">
      {label && (
        <label className="block text-sm text-gray-700 mb-2">
          {label}{' '}
          {!required ? <span className="text-gray-500">(Optional)</span> : ''}
        </label>
      )}
      <textarea
        placeholder={placeholder}
        className="border-2 w-full text-slate-900 text-sm rounded-md p-3 border-gray-300 hover:border-blue-400 focus:border-blue-500"
        value={value}
        onChange={(event) => handleChange(event?.currentTarget.value)}
        required={required}
        disabled={disabled}
      ></textarea>
    </div>
  )
}
