import { Popover } from '@headlessui/react'
import { useState } from 'react'

export type RangeSliderValueT = {
  min: number
  max: number
}

type RangeSliderParams = {
  handleChange: (value: RangeSliderValueT) => void
  value?: RangeSliderValueT
  min?: number
  max?: number
  placeholder?: string
}

export const RangeSlider = ({
  handleChange,
  value,
  min = 100,
  max = 10000,
  placeholder = ''
}: RangeSliderParams) => {
  const [open, setOpen] = useState(false)
  return (
    <Popover className="relative w-full">
      <Popover.Button className="w-full">
        <input
          type="text"
          placeholder={placeholder}
          className="border-2 cursor-pointer w-full text-slate-900 text-sm rounded-md p-3 border-gray-300 hover:border-blue-400 focus:border-blue-500"
          value={value ? `Php  ${value['min']} - ${value['max']}` : ''}
          onClick={() => setOpen(!open)}
          readOnly
        />
      </Popover.Button>

      <Popover.Panel className="absolute z-10 bg-white shadow-lg rounded-md w-full p-4 mt-1 border-2 border-gray-300">
        <div className="text-gray-500 text-sm">Coming Soon</div>
      </Popover.Panel>
    </Popover>
  )
}
